import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Spinner } from '@/components/ui/spinner';
import GalleryPhotoCard from '@/components/Gallery/GalleryPhotoCard';
import GalleryFaceCard from '@/components/Gallery/GalleryFaceCard';


const GalleryInfiniteList = ({ data, hasMore, next, page = 'photos' }) => {
    const endMessage = `You've seen all ${page === 'faces' ? 'faces' : 'photos'}.`;
    return (
        <InfiniteScroll
            dataLength={data.length}
            next={next}
            hasMore={hasMore}
            loader={<Spinner size="medium"/>}
            endMessage={<p className="p-8 text-muted-foreground text-center">{endMessage}</p>}
            className="w-full"
            style={{overflow: 'none'}}
            scrollableTarget={null}
        >
            <ResponsiveMasonry columnsCountBreakPoints={{300: 2, 500: 3, 700: 4}}>
                <Masonry>
                    {data.map((item) => (
                        page === 'faces' ? (
                            <GalleryFaceCard key={item.id} face={item}/>
                        ) : (
                            <GalleryPhotoCard key={item.id} photo={item}/>
                        )
                    ))}
                </Masonry>
            </ResponsiveMasonry>
        </InfiniteScroll>
    );
};


export default GalleryInfiniteList;
