import React from 'react';
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import moment from "moment/moment";
import {Link} from "react-router-dom";


const GalleryFaceCover = ({ event, galleryPhotoGroup }) => {
    return (
        <div className="w-full max-w-7xl flex flex-col items-center justify-between p-4 gap-6">
            <div className="w-full px-4">
                    <Link
                        className="text-xl font-weight-400 text-neutral-800 flex justify-between sm:px-6"
                        to={`/gallery/${event.slug}/faces`}
                    >
                        ←&nbsp;&nbsp;Gallery
                    </Link>
                </div>
            <Avatar className="w-36 h-36 border-2 rounded-full flex items-center justify-center">
                <AvatarImage
                    src={galleryPhotoGroup?.preview}
                    className="rounded-full object-cover w-full h-full"
                />
            </Avatar>
            <div className="flex items-center space-x-4">
                <div>
                    <h1 className="text-3xl font-weight-500 lg:text-4xl text-neutral-800 text-center">{event?.title}</h1>
                    <div
                        className="flex items-start justify-between text-center align-center text-neutral-800 font-weight-300 text-lg mt-1">
                        <p>{event?.business?.name}</p>
                        {event?.starting_date &&
                            <div className="flex ml-2">
                                &bull;
                                <p className="ml-2">
                                    <span>{moment(event?.starting_date).format("Do MMMM YYYY")}</span>
                                    {event?.ending_date && event?.ending_date !== event?.starting_date &&
                                        <span>&nbsp;-&nbsp;{moment(event?.ending_date).format("Do MMMM YYYY")}</span>
                                    }
                                </p>
                            </div>
                        }
                    </div>
                    <p className="text-center text-muted-foreground font-weight-400">
                        {galleryPhotoGroup?.photos_count} photo{galleryPhotoGroup?.photos_count > 1 && 's'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default GalleryFaceCover;
