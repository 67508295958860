import React from 'react';


const GalleryPhotoCard = ({ photo }) => {
    const fileName = photo.file_name.split(".")[0];
    return (
        <a href={photo.file} target="_blank" rel="noreferrer">
            <div className="relative group p-1">
                <img
                    key={photo.id}
                    src={photo.file}
                    alt={fileName}
                    className="w-full h-auto rounded-sm"
                />
                <div
                    className="absolute bottom-0 left-0 w-full h-full flex items-end opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-1">
                    <div className="w-full bg-black bg-opacity-60 p-2">
                        <p className="text-white text-xs text-center">{fileName}</p>
                    </div>
                </div>
            </div>
        </a>
    );
};

export default GalleryPhotoCard;
